import React, { Component } from "react"

import Header from '../../components/Header/Header';
import Content_PuntosDeRecarga from "./Content_PuntosDeRecarga";
import Footer from "../../components/Footer/Footer";

class BusApp extends Component {
    render() {
        return (

            <div class="main-container">
                <Header />
                <Content_PuntosDeRecarga />
                <Footer/>
            </div>

        )
    }
}

export default BusApp;