import React from "react";

function Content_PagoConQR() {
    return (
        <div>
      <div className="hero-image">

      <img src="./img//Banner6.png" alt="Hero" className="hero-image" />

      </div>

            <section class="features-icons bg-light text-center det-ails">
            <div className="interest-links">
            <h2 >Paga con QR</h2>
            <div className="container3">
            <div className="content3">
            <img src="./img/img03.png" alt="Imagen" className="image3" />
  
            <p className="text3">Ahora ya puedes disfrutar de la comodidad del pago con QR de Amibus. Descarga BusApp en Google Play o App Store, 
            crea tu cuenta y recarga fácilmente mediante el botón de pago. Olvídate del efectivo y vive una experiencia de transporte accesible y eficiente.</p>
            </div>
            </div>

                </div>
            </section>
        </div>
    );
}

export default Content_PagoConQR;