import React, { Component } from "react"

import Header from '../../components/Header/Header';
import Content_ComoUsarla from "./Content_ComoUsarla";
import Footer from '../../components/Footer/Footer';

class QueEsAmibus extends Component {
    render() {
        return (

            <div class="main-container">
                <Header />
                <Content_ComoUsarla />
                <Footer />
            </div>

        )
    }
}

export default QueEsAmibus;