import React from "react";

function Content_TarifasTarjetas() {
    return (
        <div>
      <div className="hero-image">

      <img src="./img//Banner8.png" alt="Hero" className="hero-image" />

      </div>

            <section class="features-icons bg-light text-center det-ails">
            <div className="interest-links">
            <h2 >Tarifas de las Tarjetas</h2>
        <div className="container3">
      <div className="content3">
        <img src="./img/img04.png" alt="Imagen" className="image3" />
  
        <p className="text3">Planifica tu viaje de manera inteligente con nuestras tarjetas Amibus. La Tarjeta Normal, diseñada para la conveniencia de todos, tiene un costo de $2,400 y está disponible en la red de comercios. Para nuestros viajeros adultos mayores, la Tarjeta Especial tiene un valor de $1,200 y puede ser adquirida en los Centros de Atención al Usuario (CAU). 
        Obtén la tuya para disfrutar de una experiencia de transporte fácil y personalizada.</p>
      </div>
    </div>

                </div>
            </section>
        </div>
    );
}

export default Content_TarifasTarjetas;