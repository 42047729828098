import React from "react";

function Content_Mapa() {
    return (
        <div>
      <div className="hero-image">

      <img src="./img//Banner14.png" alt="Hero" className="hero-image" />

      </div>

            <section class="features-icons bg-light text-center det-ails">
            <div className="interest-links">
            <h2 >Mapa</h2>



    <div className="container3">
      <div className="content4">
      <div className="group">

        <img src="./img/mapa.png" alt="Imagen" className="image4"            
         onMouseOver={e => e.currentTarget.style.transform = 'scale(1.2)'}
            onMouseOut={e => e.currentTarget.style.transform = 'scale(1)'}/>
        <div className="content-details">
        <h2>Mapa del recorrido de los buses E01</h2>
        <img src="./img/img09.png" alt="Imagen" className="image5" />
      </div>
      </div>
      </div>
    </div>


                </div>
            </section>
        </div>
    );
}

export default Content_Mapa;