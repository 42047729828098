import React, { Component } from "react"

import Header from '../../components/Header/Header';
import Content_CargaOnline from "./Content_CargaOnline";
import Footer from '../../components/Footer/Footer';

class CargaOnline extends Component {
    render() {
        return (

            <div class="main-container">
                <Header />
                <Content_CargaOnline />
                <Footer />
            </div>

        )
    }
}

export default CargaOnline;