import React, { Component } from "react"

import Header from '../../components/Header/Header';
import Content_RedDeRecargas from "./Content_RedDeRecargas";
import Footer from '../../components/Footer/Footer';

class RedDeRecargas extends Component {
    render() {
        return (

            <div class="main-container">
                <Header />
                <Content_RedDeRecargas />
                <Footer />
            </div>

        )
    }
}

export default RedDeRecargas;