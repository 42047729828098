import React from "react";

import Nav from "../Nav/Nav";

function Header() {
    return (
        <Nav />
    );

}

export default Header;