import React from "react";


function Content_CargaOnline() {
    return (
        <div>
      <div className="hero-image">

      <img src="./img//Banner9.png" alt="Hero" className="hero-image" />

      </div>

            <section class="features-icons bg-light text-center det-ails">
            <div className="interest-links">
            <h2 >Carga online</h2>
        <div className="container3">
      <div className="content3">
        <img src="./img/img05.png" alt="Imagen" className="image3" />
  
        <p className="text3">Experimenta la comodidad de recargar saldo desde la comodidad de tu hogar con nuestro servicio de Carga Online a través de la plataforma virtual Fintoc. Realiza tus recargas de manera rápida y segura, garantizando un acceso inmediato a tus fondos para utilizar en tu tarjeta Amibus. 
        Simplifica tu experiencia de viaje con la opción de recarga en línea, brindándote la flexibilidad que necesitas directamente desde tu dispositivo.</p>
      </div>
    </div>

                </div>
            </section>
        </div>
    );
}

export default Content_CargaOnline;