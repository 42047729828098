import React, { Component } from "react"

import Header from '../../components/Header/Header';
import Content_PagoConQR from "./Content_PagoConQR";
import Footer from '../../components/Footer/Footer';

class PagoConQR extends Component {
    render() {
        return (

            <div class="main-container">
                <Header />
                <Content_PagoConQR />
                <Footer />
            </div>

        )
    }
}

export default PagoConQR;