import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from "@react-google-maps/api";
import iconPR from "../Map/Tarjeta3.png"; // Imagen del marcador

const YOUR_API_KEY = "AIzaSyAugU93B3jpPvNHTqIb4YlGwrLsdHT2R1Q"; //Llave 02 Jul 2024

const mapContainerStyles = {
  width: "100%",
  height: "60vh",
  position: "relative",
  paddingBottom: "3rem",
  paddingTop: "3rem",
  paddingLeft: "5rem",
};

const menuContainerStyles = {
  position: "absolute",
  left: "5rem",
  top: "0.5rem",
  zIndex: 1,
};

const mapStyles = {
  width: "100%",
  height: "100%",
};

const defaultCenter = { lat: -23.613278, lng: -70.398889 };

const MapComponent = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: YOUR_API_KEY,
  });

  const [markers, setMarkers] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(11.7);

  useEffect(() => {
    const fetchMarkers = async () => {
      try {
        const response = await fetch("/data/markersmap.json");
        if (response.ok) {
          const data = await response.json();
          setMarkers(data);
        }
      } catch (error) {
        console.error("Error fetching markers:", error);
      }
    };
    fetchMarkers();
  }, []);

  const handleMarkerClick = (marker) => {
    setSelectedPlace(marker);
    setZoomLevel(14);
  };

  const handleCloseInfoWindow = () => {
    setSelectedPlace(null);
  };

  const handleMarkerSelect = (event) => {
    const selectedMarkerName = event.target.value;
    const marker = markers.find((m) => m.name === selectedMarkerName);
    if (marker) {
      setSelectedPlace(marker);
      setZoomLevel(14);
    }
  };

  if (!isLoaded) return <div>Cargando mapa...</div>;

  return (
    <div style={mapContainerStyles}>
      <div style={menuContainerStyles}>
        <select value={selectedPlace ? selectedPlace.name : ""} onChange={handleMarkerSelect}>
          <option value="">Puntos de recargas</option>
          {markers.map((marker, index) => (
            <option key={index} value={marker.name}>
              {marker.name}
            </option>
          ))}
        </select>
      </div>

      <GoogleMap mapContainerStyle={mapStyles} center={selectedPlace ? selectedPlace.position : defaultCenter} zoom={zoomLevel}>
        {markers.map((marker, index) => (
          <Marker
            key={index}
            position={marker.position}
            onClick={() => handleMarkerClick(marker)}
            icon={{
              url: iconPR,
              scaledSize: new window.google.maps.Size(40, 40), // Tamaño personalizado del icono
            }}
          />
        ))}

        {selectedPlace && (
          <InfoWindow position={selectedPlace.position} onCloseClick={handleCloseInfoWindow}>
            <div>
              <h5 className="marker-text">{selectedPlace.name}</h5>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
};

export default MapComponent;
