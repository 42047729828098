import React from "react";


function Content_ViajeDeEmer() {
    return (
        <div>
      <div className="hero-image">

      <img src="./img//Banner10.png" alt="Hero" className="hero-image" />

      </div>

            <section class="features-icons bg-light text-center det-ails">
            <div className="interest-links">
   
       
        
              
        <div className="container3">
      <div className="content5">
      <div className="group">

        <img src="./img/img06.png" alt="Imagen" className="image4" />
        <div className="content-details">
        <h2>Viaje de Emergencia</h2>
        <p className="text3">En situaciones imprevistas, todas las tarjetas amibus ofrecen la conveniencia de un Viaje de Emergencia. Este servicio te permite realizar 
        un viaje único a cuenta de tu próxima recarga, en caso de que tu saldo sea insuficiente. Al realizar la siguiente carga, se descontará la cuota de transporte 
        utilizada durante el viaje de emergencia. Este beneficio se activa a partir de la segunda carga de la tarjeta, proporcionándote una red de seguridad confiable.</p>
      </div>
      </div>
      </div>
    </div>

    <div className="container3">
      <div className="content5">
      <div className="group">

       
        <div className="content-details">
        <h2>Funcionamiento</h2>
        <p className="text3">Si te encuentras con un saldo insuficiente, puedes aprovechar el Viaje de Emergencia para realizar un único viaje, el cual deberá ser 
        repuesto en tu próxima carga. El Saldo de Emergencia está disponible durante toda la jornada de servicio, de lunes a domingo. Recuerda que este beneficio se activa 
        a partir de la segunda carga de la tarjeta, en caso de que sea nueva. Simplificamos tu viaje, brindándote flexibilidad y tranquilidad en cualquier momento que lo necesites.</p>
        
      </div>
      <img src="./img/img07.png" alt="Imagen" className="image4" />
      </div>
      </div>
    </div>




                </div>
            </section>
        </div>
    );
}

export default Content_ViajeDeEmer;